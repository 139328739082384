import React from "react";

export default function UserActiveStatus(props) {
    const {doctor,style} = props;
    return (
        <>
        {(style == "smart")?(
            <div
            className="user-status d-flex flex-wrap w-100 justify-end absolute top-0"
            data-user-id={doctor.id}
        >
            <span
                className={`status-icon w-3 h-3 rounded-full  ${
                    doctor.active_status ? (doctor.availability == 'busy'?"bg-red-500":"bg-green-500") : "bg-gray-500 text-white "
                } `}
            >
            </span>
        </div>
        ):(
        <div
            className="user-status d-flex flex-wrap w-100 justify-center absolute -bottom-4 "
            data-user-id={doctor.id}
        >
            <span
                className={`status-text px-8 py-2 rounded-full text-white border-2 border-white  ${
                    doctor.active_status ?(doctor.availability == 'busy'?"bg-red-500":"bg-green-500")  : "bg-gray-500  "
                } `}
            >
                {doctor.active_status ?(doctor.availability == 'busy'?"BUSY":"ONLINE") : "OFFLINE"}
            </span>
        </div>
        )}
        </>
    );
}
